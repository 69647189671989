import React, { useRef, useState } from "react";
import { Upload } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Images } from "../../../theme";
import BaseButton from "../../../components/button";
import { FILE_DIMENSION, FILE_SIZE, TYPE_FILE } from "../../../constants";
import {
  ImageWrapper,
  ModalWrapper,
  PreviewModalVideoWrapper,
  VideoWrapper,
} from "./styled";
import { handleAspectRatio } from "../../../utils/common";
import useVideoPlayer from "../../../hooks/useVideoPlayer";

const { Dragger } = Upload;

const Files = ({
  fileUpload,
  handleFileChange,
  onChange,
  selectType,
  handleDeleteFile,
  srcIcon,
  className,
  aspectRatio,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewVideoOpen, setPreviewVideoOpen] = useState(false);

  const videoRef = useRef();
  const {
    playerState,
    togglePlay,
    skipBackward,
    skipForward,
    handleVideoProgress,
    toggleMute,
    toggleFullScreen,
    currentTime,
    totalTime,
    handlePlayVideo
    // setPlayerState,
  } = useVideoPlayer(videoRef);

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreviewVideo = async () => {
    setPreviewVideoOpen(true);
  };

  const handleCancelVideo = () => setPreviewVideoOpen(false);

  return (
    <>
      {!fileUpload ? (
        <Dragger
          className={className}
          showUploadList={false}
          onChange={(info) => {
            onChange(info.fileList);
          }}
          onDrop={(e) => {
            onChange(e.dataTransfer.files);
          }}
          beforeUpload={(file) => {
            handleFileChange(file);
          }}
          accept={
            selectType === TYPE_FILE.IMAGE
              ? "image/png, image/jpeg, image/gif"
              : selectType === TYPE_FILE.SLIDE
              ? "image/png, image/jpeg"
              : "video/mp4, video/mov, video/quicktime"
          }
          disabled={!selectType}
        >
          <div className="form-row upload">
            <div className="upload-text">
              <img src={srcIcon} alt="Upload Icon" />
              <p className="text-12">Click to upload or drag and drop</p>
              {(selectType === TYPE_FILE.IMAGE ||
                selectType === TYPE_FILE.SLIDE) && (
                <p className="small-text">
                  <div>Maximum file size: {FILE_SIZE.IMAGE}MB</div>
                  <div>Maximum dimensions: {FILE_DIMENSION} x {FILE_DIMENSION} px</div>
                </p>
              )}
              {selectType === TYPE_FILE.VIDEO && (
                <p className="small-text">
                  <div> Maximum file size: {FILE_SIZE.VIDEO}MB</div>
                  <div> Recommended resolution: 360p</div>
                  <div> Duration: Approximately 30 seconds</div>
                </p>
              )}
            </div>
          </div>
        </Dragger>
      ) : (
        <div className={`dp-flex custom ${className ? "flex-start" : ""}`}>
          <div
            className={`preview-${
              selectType === TYPE_FILE.IMAGE || selectType === TYPE_FILE.SLIDE
                ? "image"
                : "video"
            }`}
          >
            {selectType === TYPE_FILE.IMAGE ||
            selectType === TYPE_FILE.SLIDE ? (
              <ImageWrapper>
                <img
                  src={URL.createObjectURL(fileUpload)}
                  alt="preview"
                  className="image"
                  onClick={handlePreview}
                />
                <div className="icon" onClick={handlePreview}>
                  <EyeOutlined
                    className="icon-eye"
                    style={{ fontSize: "24px" }}
                  />
                </div>
              </ImageWrapper>
            ) : (
              // <ReactPlayer url={URL.createObjectURL(fileUpload)} />
              <VideoWrapper
                width={handleAspectRatio(aspectRatio).width / 2}
                height={handleAspectRatio(aspectRatio).height / 2}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handlePreviewVideo();
                  }}
                  className="btn-preview"
                >
                  <img src={Images.ICON_PLAY} alt="" />
                </button>
                <video disablePictureInPicture className="video custom-video">
                  <source
                    src={URL.createObjectURL(fileUpload)}
                    type={
                      fileUpload.type === "video/quicktime"
                        ? "video/mp4"
                        : fileUpload.type
                    }
                  />
                </video>
              </VideoWrapper>
            )}
            <BaseButton
              className="btn-action delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteFile(fileUpload);
              }}
            >
              <img src={Images.ICON_TRASH} alt="icon-trash" />
            </BaseButton>
          </div>
        </div>
      )}
      {previewVideoOpen && (
        <PreviewModalVideoWrapper
          open={true}
          footer={null}
          onCancel={handleCancelVideo}
          className="custom-preview"
          width={handleAspectRatio(aspectRatio).width}
          height={handleAspectRatio(aspectRatio).height}
          closeIcon={<img src={Images.ICON_CLOSE_2} alt="close-icon" />}
        >
          <div className="video-content">
            <video
              disablePictureInPicture
              style={{ maxWidth: "1000px", maxHeight: "80vh" }}
              className="custom-video"
              ref={videoRef}
              onPlay={handlePlayVideo}
              onPause={handlePlayVideo}
            >
              <source src={URL.createObjectURL(fileUpload)} type="video/mp4" />
            </video>
            <div className="controls">
              {/* <div className="name-video">{requestDetail.name}</div> */}
              <div className="current-time">{currentTime}</div>
              <p className="temp">/</p>
              <div className="total-time">{totalTime}</div>
              <input
                className="range"
                type="range"
                min="0"
                max="100"
                value={playerState.progress ?? 0}
                onChange={(e) => handleVideoProgress(e)}
              />

              <button className="btn-control btn-play" onClick={togglePlay}>
                {playerState.isPlaying && playerState.progress < 100 ? (
                  <img src={Images.ICON_PAUSE_VID} alt="" />
                ) : (
                  <img src={Images.ICON_PLAY_VID} alt="" />
                )}
              </button>

              <button className="btn-control btn-prev" onClick={skipBackward}>
                <img src={Images.ICON_PREV} alt="" />
              </button>
              <button className="btn-control btn-next" onClick={skipForward}>
                <img src={Images.ICON_NEXT} alt="" />
              </button>

              <button className="btn-control btn-mute" onClick={toggleMute}>
                {playerState.isMuted ? (
                  <img src={Images.ICON_MUTE} alt="" />
                ) : (
                  <img src={Images.ICON_UN_MUTE} alt="" />
                )}
              </button>
              <button
                className="btn-control btn-full"
                onClick={toggleFullScreen}
              >
                <img src={Images.ICON_SHOW_FULL_SCREEN} alt="" />
              </button>
            </div>
          </div>
        </PreviewModalVideoWrapper>
      )}
      {previewOpen && (
        <ModalWrapper
          open={true}
          footer={null}
          onCancel={handleCancel}
          className="custom-preview"
          width={handleAspectRatio(aspectRatio).width}
          height={handleAspectRatio(aspectRatio).height}
          closeIcon={<img src={Images.ICON_CLOSE_2} alt="close-icon" />}
        >
          <img
            alt="example"
            className="custom-image"
            src={URL?.createObjectURL(fileUpload)}
          />
        </ModalWrapper>
      )}
    </>
  );
};

export default Files;
