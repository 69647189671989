import styled from 'styled-components';
import { Modal } from 'antd';

export const CreateModalWrapper = styled(Modal)`
  background: linear-gradient(270deg, #052b86 0%, #03153f 100%);
  border-radius: 20px;
  width: 937px !important;
  .custom-preview{
    padding: 0px;
    .ant-modal-content{
      .ant-modal-body{
        padding: 0px
      }
    }
  }
  .ant-modal-content,
  .ant-modal-header {
    color: #fff;
    background: transparent;
  }

  .ant-modal-title {
    color: #fff;
    font-size: 20px;
  }

  .ant-modal-header {
    border: unset;
    padding: 30px;
  }

  .ant-modal-content {
    max-height: 95vh;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
      height: 9px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: unset;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #2b6ae680;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #2b6ae680;
    }
  }

  .dp-flex {
    display: flex;
    gap: 6px;
    .ant-upload-drag {
      aspect-ratio: ${({ ratio }) => (ratio ? ratio : '4/3')};
      .ant-upload-drag-container {
        height: 100%;
      }
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  .btn-group {
    justify-content: flex-end;
  }

  .required {
    color: red;
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .text-12 {
    font-size: 12px;
  }
  .small-text {
    font-size: 10px;
    color: #757b8c;
  }

  .preview-image {
    width: 155px;
    aspect-ratio: ${({ ratio }) => (ratio ? ratio : '4/3')};
    position: relative;
    cursor: pointer;
    padding: 6px;
    border: 1px solid #FFF;
    border-radius: 4px;
    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
      object-fit: cover;
      // border: 1px solid #156df2;
    }

    .btn-action {
      position: absolute;
      z-index: 6;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 8px;

      &.delete {
        background-color: #e03137;
      }
      &.delete:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
        scale: 1.1;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .preview-video {
    /* width: 400px;
    height: 150px; */
    position: relative;
    border-radius: 6px;

    .video {
      /* width: 400px;
      height: 100%; */
      position: absolute;
      z-index: 5;
      border: 1px solid #FFF;
      border-radius: 4px;
      padding: 6px;
    }

    .btn-action {
      position: absolute;
      z-index: 6;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 8px;

      &.delete {
        background-color: #e03137;
      }
      &.delete:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
        scale: 1.1;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .custom {
    justify-content: center;
    align-items: center;
  }

  .flex-start {
    justify-content: flex-start;
  }

  input::placeholder,
  textarea::placeholder,
  .ant-select-selection-placeholder {
    color: #757b8c;
  }
`;
